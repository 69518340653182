import { getFormValues } from 'redux-form';
import qs from 'qs';
import * as R from 'ramda';

import { terms } from 'models/QualifiedRate';
import { Filters, productTypes, quoteTypes } from 'sagas/rates.sagas';
import {
    APPLICATION_FORMS,
    ACCOUNT_INFORMATION_FORM
} from 'constants/appConstants';
import { propertyTypes } from 'types/properties';
import { validateRefinanceAmount } from 'lib/validators';

export const getRatesFilterFormData = (state: any): Filters => {
    const formValues: any = getFormValues(APPLICATION_FORMS.RATES_FILTER)(
        state
    );

    return formValues && formValues.ratesFilter;
};

export const getRatesFilter = (state: any): Filters => {
    const queryParams = qs.parse(state.router.location.search, {
        ignoreQueryPrefix: true
    });

    const formValues = getRatesFilterFormData(state);

    const params = formValues || queryParams;

    const defaultDownpaymentPercentage =
        params.ownerOccupied === 'true' && +params.propertyValue < 1_500_000
            ? 0.15
            : 0.2;

    return {
        ...params,
        downPaymentAmount:
            (params.quoteType === quoteTypes.NEW && params.downPaymentAmount) ||
            Math.ceil(params.propertyValue * defaultDownpaymentPercentage) ||
            undefined,
        downPaymentRatio:
            params.quoteType === quoteTypes.RENEWAL && params.downPaymentRatio,
        term: params.term || terms.FIVEYEAR,
        amortization: params.amortization ? +params.amortization : 25,
        productType: params.productType || getRateProductType(state),
        ownerOccupied: params.ownerOccupied || 'true',
        regionCode: params.regionCode || 'default',
        propertyType: params.propertyType || propertyTypes.HOME_OR_CONDO,
        mortgageAmount: params.mortgageAmount && +params.mortgageAmount,
        propertyValue: params.propertyValue && +params.propertyValue,
        additionalFundAmount:
            params.additionalFundAmount && +params.additionalFundAmount,
        postalCode: queryParams?.postalCode || params.postalCode
    };
};

export const getRateProductType = (state: any): productTypes | undefined =>
    R.path(['rates', 'matrix', 'firstSelection', 'products', 0, 'type'], state);

export const hasProduct = (state: any): boolean =>
    R.compose(
        R.length,
        R.pathOr(
            [],
            ['rates', 'matrix', 'firstSelection', 'products', 0, 'type']
        )
    )(state) >= 1;

export const getQuoteHasMatrix = (state: any): boolean =>
    R.pathOr(null, ['rates', 'matrix'])(state) !== null;

export const getAccountInformation = (state: any) => {
    return getFormValues(ACCOUNT_INFORMATION_FORM)(state) as any;
};

export const isMortgageAmountValid = (formValues: Filters): boolean => {
    if (!formValues.propertyValue || !formValues.mortgageAmount) return false;

    if (formValues.propertyValue < formValues.mortgageAmount) return false;

    return true;
};

export const isRefinanceAmountValid = (formValues: Filters): boolean => {
    return validateRefinanceAmount(
        formValues.mortgageAmount,
        formValues.additionalFundAmount || 0,
        formValues.propertyValue
    );
};

export const selectFirstSelectionQuoteId = (state: any) =>
    state?.rates?.matrix?.firstSelection?.id;
