import { path } from 'ramda';
import { getFormValues } from 'redux-form';

import { TypographI18nKeys } from 'components/typograph';
import { WIZARDS } from 'constants/appConstants';
import { validateRefinanceAmount } from 'lib/validators';
import { Timings } from 'types/application/application';
import { FormContact } from 'types/mortgage.interface';

type ReduxState = any;

export const selectNewMortgageWizard = (state: any) =>
    selectFromFormName(WIZARDS.NEW_MORTGAGE_QUOTE)(state);

export const selectRefinanceWizard = (state: any) =>
    selectFromFormName(WIZARDS.REFINANCE_MORTGAGE_QUOTE)(state);

export const selectRenewalWizard = (state: any) =>
    selectFromFormName(WIZARDS.RENEWAL_MORTGAGE_QUOTE)(state);

export const isOwnerOccupiedSelected = (state: any): boolean =>
    !!path<boolean>(['ownerOccupied'], selectNewMortgageWizard(state));

export const selectFromFormName = (formName: string) => (state: any) =>
    path(['form', formName, 'values'], state);

export const newMortgagePropertyValue = (state): number =>
    path(['propertyValue'], selectNewMortgageWizard(state)) || 0;

export const newMortgageDownPayment = (state): number =>
    path(['downPaymentAmount'], selectNewMortgageWizard(state)) || 0;

export const newMortgageTiming = (state): Timings =>
    path(['timing'], selectNewMortgageWizard(state)) as Timings;

export const newMortgageContact = (state): FormContact =>
    path(['contact'], selectNewMortgageWizard(state)) as FormContact;

export const getMortgageName = (formName: string) => (state: any) =>
    path(['contactName'], selectFromFormName(formName)(state));
export const getMortgageContact = (formName: string) => (state: any) =>
    path(['contact'], selectFromFormName(formName)(state));

export const refinanceMortgageAmount = (state): number =>
    path(['mortgageAmount'], selectRefinanceWizard(state)) || 0;

export const refinancePropertyValue = (state): number =>
    path(['propertyValue'], selectRefinanceWizard(state)) || 0;

export const refinanceOwnerOccupied = (state): boolean =>
    path(['ownerOccupied'], selectRefinanceWizard(state)) || false;

export const refinanceAdditionalFundAmount = (state): number =>
    path(['additionalFundAmount'], selectRefinanceWizard(state)) || 0;

export const newMortgageDownPaymentPercentage = (state): number => {
    const propertyValue = newMortgagePropertyValue(state);
    const downPayment = newMortgageDownPayment(state);

    if (propertyValue <= 0 || downPayment <= 0) return 0;

    const percentage = (downPayment / propertyValue) * 100;

    return Math.round(percentage * 100) / 100;
};

export const newMortgageDownpaymentInformation = (
    state
): TypographI18nKeys | undefined => {
    const propertyValue = newMortgagePropertyValue(state);
    const downPayment = newMortgageDownPayment(state);

    if (propertyValue <= 0 || downPayment <= 0) return undefined;

    const downPaymentPercentage = newMortgageDownPaymentPercentage(state);
    const isRentalProperty = !isOwnerOccupiedSelected(state);
    const downPaymentRemainder = (propertyValue - 500_000) * 0.1;
    const isMoreThan1Pt5Mil = propertyValue >= 1_500_000;
    const isMoreThan500k = propertyValue > 500_000;
    const isLessThan500k = !isMoreThan500k;

    if (isRentalProperty && downPaymentPercentage < 20) {
        return 'prequalify.downpaymentWarnRental';
    }

    // 1.5 mil plus
    if (isMoreThan1Pt5Mil && downPaymentPercentage < 20) {
        return 'prequalify.downpaymentWarnMillionPlus';
    }

    // less than 500k
    if (isLessThan500k && downPaymentPercentage < 5) {
        return 'prequalify.downpaymentWarnMin5Percent';
    }

    // more than 500k
    if (isMoreThan500k && 25_000 + downPaymentRemainder > downPayment) {
        return 'prequalify.downpaymentWarnInsuranceCostsSpecial';
    }

    return undefined;
};

export const isRefinanceValidAmount = (state): boolean => {
    const mortgageAmount = refinanceMortgageAmount(state);
    const additionalFundAmount = refinanceAdditionalFundAmount(state);
    const propertyValue = refinancePropertyValue(state);

    return validateRefinanceAmount(
        mortgageAmount,
        additionalFundAmount,
        propertyValue
    );
};

export const isValidLender = (formName: string) => (state: any): boolean => {
    const formValues: {
        lender?: string;
        lenderOther?: string;
    } = getFormValues(formName)(state);

    const { lender, lenderOther } = formValues;

    // @ts-ignore
    return lender === 'OTHER'
        ? lenderOther && lenderOther.length <= 36
        : !!lender?.length;
};

export const getWizardInitialValues = (state: ReduxState): boolean => {
    const { mortgageWizards } = state;

    return mortgageWizards?.initialValues;
};

export const showGAQPhoneNumber = (formName: string) => (
    state: ReduxState
): boolean => {
    if (formName !== WIZARDS.NEW_MORTGAGE_QUOTE) return false;

    const contact = newMortgageContact(state);
    const isQuebecOrOntario =
        contact?.province === 'ON' || contact?.province === 'QC';

    const downPaymentPercentage = newMortgageDownPaymentPercentage(state);
    const isLessThan20DownPayment = downPaymentPercentage < 20;

    const timing = newMortgageTiming(state);
    const isLiveDeal =
        timing === Timings.MADE_OFFER || timing === Timings.MAKING_OFFER_SOON;

    const meetsPhoneNumberRequirements =
        isQuebecOrOntario && isLessThan20DownPayment && isLiveDeal;

    return meetsPhoneNumberRequirements;
};
