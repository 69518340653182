import { TranslationKeys } from 'messages';

export const getDownPaymentPercentage = (
    propertyValue?: number,
    downPayment?: number,
    roundResult = true
): number => {
    if (!propertyValue || !downPayment) return 0;
    const percentage = (downPayment / propertyValue) * 100;
    return roundResult ? Math.round(percentage * 100) / 100 : percentage;
};

export const getDownPaymentValue = (
    propertyValue: number,
    downPaymentPercentage: number
): number => {
    if (!propertyValue || !downPaymentPercentage) return 0;

    const downPayment = (downPaymentPercentage * propertyValue) / 100;

    return downPayment;
};

// Minimum amount of down payment 5% of the purchase price
const MIN_DOWNPAYMENT_OWN_PERCENT = 5;
// 5% of the first $500,000, which is equal to $25,000
const BASE_DOWN_PAYMENT_REMAINDER = 25_000;
// 10% for the portion of the purchase price above $500,000
const REMAINDER_PERCENTAGE = 0.1;
const HALF_MILLION_DOWN_PAYMENT_TRIGGER = 500_000;
const ONE_PT5_MILLION_DOWN_PAYMENT_TRIGGER = 1_500_000;
// $1.5 million or more - Minimum amount of down payment 20% of the purchase price
// purpose is RENTAL
// *** NOTE: OWNER_OCCUPIED_AND_RENTAL is not 20% but the 5% + 10% remainder rule ***
const MIN_DOWNPAYMENT_RENTAL_MILLION_PERCENT = 20;
/**
 * Validates that the total downpayment amount is sufficient based
 * on the value of the property and its rental status.
 *
 * For rental properties, the downpayment must be at least 20% of the property value.
 *
 * For properties valued at more than $1,500,000, the downpayment must be at least 20% of the property value.
 *
 * For properties valued between $500,000 and $1,500,000, the downpayment must be at least
 * 5% of the first $500,000 of the property value AND 10% of the portion of the property value above $500,000.
 *
 * For properties valued below $500,000, the downpayment must be at least 5% of the property value.
 *
 */
export const isViableDownPayment = (
    propertyValue?: number,
    downPayment?: number,
    isRentalProperty?: boolean
): boolean => {
    if (!propertyValue || !downPayment) return false;

    const downPaymentPercentage = (downPayment / propertyValue) * 100;
    const downPaymentRemainder =
        (propertyValue - HALF_MILLION_DOWN_PAYMENT_TRIGGER) *
        REMAINDER_PERCENTAGE;

    if (
        isRentalProperty &&
        downPaymentPercentage < MIN_DOWNPAYMENT_RENTAL_MILLION_PERCENT
    ) {
        return false;
    }

    if (propertyValue > HALF_MILLION_DOWN_PAYMENT_TRIGGER) {
        // 1.5 mil plus
        if (
            propertyValue >= ONE_PT5_MILLION_DOWN_PAYMENT_TRIGGER &&
            downPaymentPercentage < MIN_DOWNPAYMENT_RENTAL_MILLION_PERCENT
        ) {
            return false;
        }

        // more than 500k
        if (BASE_DOWN_PAYMENT_REMAINDER + downPaymentRemainder > downPayment) {
            return false;
        }
    }

    // less than 500k
    if (
        propertyValue <= HALF_MILLION_DOWN_PAYMENT_TRIGGER &&
        downPaymentPercentage < MIN_DOWNPAYMENT_OWN_PERCENT
    ) {
        return false;
    }

    return true;
};

export const getDownPaymentInformation = (
    propertyValue?: number,
    downPayment?: number,
    isRentalProperty?: boolean
): TranslationKeys | undefined => {
    if (!propertyValue || !downPayment) return undefined;

    const downPaymentRemainder =
        (propertyValue - HALF_MILLION_DOWN_PAYMENT_TRIGGER) *
        REMAINDER_PERCENTAGE;
    const downPaymentPercentage = getDownPaymentPercentage(
        propertyValue,
        downPayment
    );

    if (
        isRentalProperty &&
        downPaymentPercentage < MIN_DOWNPAYMENT_RENTAL_MILLION_PERCENT
    ) {
        return 'prequalify.downpaymentWarnRental';
    }

    // 1.5 mil plus
    if (
        propertyValue >= ONE_PT5_MILLION_DOWN_PAYMENT_TRIGGER &&
        downPaymentPercentage < MIN_DOWNPAYMENT_RENTAL_MILLION_PERCENT
    ) {
        return 'prequalify.downpaymentWarnMillionPlus';
    }

    // less than 500k
    if (propertyValue <= HALF_MILLION_DOWN_PAYMENT_TRIGGER) {
        if (downPaymentPercentage < MIN_DOWNPAYMENT_OWN_PERCENT) {
            return 'prequalify.downpaymentWarnMin5Percent';
        }
        if (downPaymentPercentage < MIN_DOWNPAYMENT_RENTAL_MILLION_PERCENT) {
            return 'prequalify.downpaymentWarnInsuranceCosts';
        }
    }

    // more than 500k
    if (BASE_DOWN_PAYMENT_REMAINDER + downPaymentRemainder > downPayment) {
        return 'prequalify.downpaymentWarnInsuranceCostsSpecial';
    }

    if (downPaymentPercentage < MIN_DOWNPAYMENT_RENTAL_MILLION_PERCENT) {
        return 'prequalify.downpaymentWarnInsuranceCosts';
    }

    return undefined;
};
